import React, { Component } from 'react'

import CharacterStyle from './CharacterStyle';
import AdditionallyStyle from '../additionally/AdditionallyStyle';

export default class EditCategories extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tipStyle: 'hairstyle',
        }

        this.handlerClick = this.handlerClick.bind(this);
    }
    handlerClick(event){
        if(event.currentTarget.classList.contains('active')) return

        this.setState ({
            tipStyle: event.currentTarget.id,
        })

        let spanElements = document.getElementsByClassName('characters__leftBar')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }
    
    render() {
        return (
            <div className="characters__rows">
                <div className="characters__style">
                    <div className="characters__leftBar">
                        <span className="active" onClick={this.handlerClick} id="hairstyle">
                            <img src="/img/charactersEdit/icons/head/hairstyle.png" alt="hairstyle" />
                        </span>
                        <span onClick={this.handlerClick} id="brows">
                            <img src="/img/charactersEdit/icons/head/brows.png" alt="brows" />
                        </span>
                        <span onClick={this.handlerClick} id="beard">
                            <img src="/img/charactersEdit/icons/head/beard.png" alt="beard" />
                        </span>
                        <span onClick={this.handlerClick} id="hair">
                            <img src="/img/charactersEdit/icons/head/hair.png" alt="hair" />
                        </span>
                    </div>
                    <CharacterStyle tipStyle={this.state.tipStyle} sex={false} />
                    <AdditionallyStyle tipStyle={this.state.tipStyle}/>
                </div>
            </div>
        )
    }
}
