import React, { Component } from 'react'
import { Link } from "react-router-dom";

// import logo from './img/logo.png'

import './css/style.css'

export default class Login extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            user: "",
            password: "",
            error:{
                errorActual: false,
                errorMessage: ""
            },
        }
        window.handlerErrorMessage = this.handlerErrorMessage.bind(this);

        this.handlerChangeLogin = this.handlerChangeLogin.bind(this);
        this.handlerChangePassword = this.handlerChangePassword.bind(this);

        this.sendLoginData = this.sendLoginData.bind(this);

    }
    handlerErrorMessage(messageValue,errorValue){
        this.setState({
            error:{
                errorActual: errorValue,
                errorMessage: messageValue
            }
        })
    }

    handlerChangeLogin(event){
        this.setState({
            user: event.target.value
        })

        if(event.target.value.length < 3 || event.target.value.length > 20){
            event.target.style.border = "2px solid #ff8069";
            this.handlerErrorMessage("Логин должен быть больше чем 3 и меньше чем 20 символа.",true);
        }
        else if(!event.target.value.match(/^[a-zA-Z0-9]+$/i)){
            event.target.style.border = "2px solid #ff8069";
            this.handlerErrorMessage("Для логина можно использовать только латинские буквы и цифры.",true);
        }
        else{
            event.target.style.border = "";
            this.handlerErrorMessage("",false);
        }
    }

    handlerChangePassword(event){
        this.setState({
            password: event.target.value
        })

        if(event.target.value.length < 6 || event.target.value.length > 24){
            event.target.style.border = "2px solid #ff8069";
            this.handlerErrorMessage("Пароль должен быть больше чем 6 и меньше чем 24 символа.",true);
        }
        else{
            event.target.style.border = "";
            this.handlerErrorMessage("",false);
        }
    }

    sendLoginData(event){
        event.preventDefault();

        if(!this.state.user || !this.state.password){
            this.handlerErrorMessage("Нужно заполнить поля Логин / Пароль.",true);
            return;
        }

        if(this.state.error.errorActual){
            return;
        }

        window.mp.trigger('handlerLoginData',JSON.stringify(this.state));
    }

    render() {
        return (
            <div className="wrapper">
                <div className="content">
                    <div className="box">
                        <div className="box__link">
                            <ul>
                                <li><Link className="active" to="/Login">Авторизация</Link></li>
                                <li><Link to="/Register">Регистрация</Link></li>
                            </ul>
                        </div>
                        <div className="box__rows">
                            <div className="box__logo">
                                <img src="img/authorization/logo.png" alt=""/>
                            </div>
                            <div className="box__subtitle">
                                <p>Добро пожаловать на сервер.</p>
                                <p>Желаем вам удачной игры на проект Box Role Play!</p>
                            </div>
                            <div className="box__error-mess">
                                <span>{this.state.error.errorMessage}</span>
                            </div>
                            <form className="box__form" onSubmit={this.sendLoginData}>
                                <input type="text" placeholder="Логин" onChange={this.handlerChangeLogin} />
                                <input type="password" placeholder="Пароль" onChange={this.handlerChangePassword} />
                                <button type="submit">Войти</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
