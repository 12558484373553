import React, { Component } from 'react'

import './css/style.css';

export default class Characters extends Component {
    constructor(props) {
        super(props)
    
        this.state = {             
        }
    }
    
    render() {
        return (
            <div className="wrapper">
                <div className="content">
                    <div className="header">
                        <div className="header__exit">
                            <span>
                                <img src="img/characters/exit.png" alt="" />
                            </span>
                        </div>
                        <div className="header__donat-stats">
                            <div className="header__donat-info">
                                <p>0 <span>Bcoins</span></p>
                            </div>
                            <div className="header__donate-server">
                                <span>Box Role Play</span>
                            </div>
                        </div>
                    </div>
        
                    <div className="main">
                        <div className="main__characters">
                            <div className="main__character">
                                <div className="main__character-header">
                                    <div className="main__character-icons">
                                        <img src="img/characters/user.png" alt="" />
                                    </div>
                                    <div className="main__character-title">
                                        <p>Бесплатно</p>
                                    </div>
                                </div>
                                <div className="main__character-name">
                                    <p>Свободный</p>
                                </div>
                                <div className="main__character-surname">
                                    <p>Слот</p>
                                </div>
                                <div className="main__character-link">
                                    <span>Создать</span>
                                </div>
                                <div className="main__character-bg">
                                    <img src="img/characters/bg.png" alt="" />
                                </div>
                            </div>
                            <div className="main__character">
                                <div className="main__character-header">
                                    <div className="main__character-icons">
                                        <img src="img/characters/user.png" alt="" />
                                    </div>
                                    <div className="main__character-title">
                                        <p>Бесплатно</p>
                                    </div>
                                </div>
                                <div className="main__character-name">
                                    <p>Свободный</p>
                                </div>
                                <div className="main__character-surname">
                                    <p>Слот</p>
                                </div>
                                <div className="main__character-link">
                                    <span>Создать</span>
                                </div>
                                <div className="main__character-bg">
                                    <img src="img/characters/bg.png" alt="" />
                                </div>
                            </div>
                            <div className="main__character">
                                <div className="main__character-header">
                                    <div className="main__character-icons">
                                        <img src="img/characters/user.png" alt="" />
                                    </div>
                                    <div className="main__character-title">
                                        <p>Бесплатно</p>
                                    </div>
                                </div>
                                <div class="main__character-name">
                                    <p>Свободный</p>
                                </div>
                                <div class="main__character-surname">
                                    <p>Слот</p>
                                </div>
                                <div class="main__character-link">
                                    <span>Создать</span>
                                </div>
                                <div class="main__character-bg">
                                    <img src="img/characters/bg.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}