import React, { Component } from 'react'

export default class EditButton extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }
    }
    
    render() {
        return (
            <div className="button">
                <span>Продолжить</span>
            </div>
        )
    }
}
