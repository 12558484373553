import React, { Component } from 'react'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
  } from "react-router-dom";

import './css/style.css'

import EditInfo from './edit/EditInfo'
import EditParent from './edit/EditParent'
import EditGene from './edit/EditGene'
import EditStyle from './edit/EditStyle'
import EditDisadvantage from './edit/EditDisadvantage';
import EditClothes from './edit/EditClothes';

import EditButton from './edit/EditButton'

export default class CharactersEdit extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
        }

        this.handlerClickList = this.handlerClickList.bind(this);
    }

    handlerClickList(event){
      let activeElements = document.getElementsByClassName('characters__categories-type active');

      for(let el of activeElements){
        el.classList.remove('active');
      }

      event.currentTarget.classList.add('active');
    }
    
    render() {
        return (
          <div className="wrapper">

            <Router>
              <div className="characters">

                <Switch>
                  <Route path="/CharactersEdit/EditInfo">
                    <EditInfo />
                  </Route>
                  <Route path="/CharactersEdit/EditParent">
                    <EditParent />
                  </Route>
                  <Route path="/CharactersEdit/EditGene">
                    <EditGene />
                  </Route>
                  <Route path="/CharactersEdit/EditStyle">
                    <EditStyle />
                  </Route>
                  <Route path="/CharactersEdit/EditDisadvantage">
                    <EditDisadvantage />
                  </Route>
                  <Route path="/CharactersEdit/EditClothes">
                    <EditClothes />
                  </Route>
                </Switch>

                <nav>
                  <div className="characters__categories">
                      <Link className="characters__categories-type active" to="/CharactersEdit/EditInfo" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/info.png" alt="" />
                      </Link>
                      <Link className="characters__categories-type" to="/CharactersEdit/EditParent" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/manAndWoman.png" alt="" />
                      </Link>
                      <Link className="characters__categories-type" to="/CharactersEdit/EditGene" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/genetic.png" alt="" />
                      </Link>
                      <Link className="characters__categories-type" to="/CharactersEdit/EditStyle" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/hairstyles.png" alt="" />
                      </Link>
                      <Link className="characters__categories-type" to="/CharactersEdit/EditDisadvantage" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/flaws.png" alt="" />
                      </Link>
                      <Link className="characters__categories-type" to="/CharactersEdit/EditClothes" onClick={this.handlerClickList}>
                          <img src="/img/charactersEdit/icons/categories/clothes.png" alt="" />
                      </Link>
                  </div>
                </nav>

              </div>
            </Router>

            <EditButton />
          </div>
        )
    }
}
