import React, { Component } from 'react'

export default class CharacterStyle extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
            hairstyleIndex: 0, 

            eyebrows: 0,
            facialHair: 0,
            chestHair: 0,

            hairstyleM: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
            hairstyleF: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
        }

        this.handlerChangeEyebrows = this.handlerChangeEyebrows.bind(this);
        this.handlerChangeFacialHair = this.handlerChangeFacialHair.bind(this);
        this.handlerChangeChestHair = this.handlerChangeChestHair.bind(this);
        this.handlerClick = this.handlerClick.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            hairstyleIndex: localStorage.getItem("hairstyleIndex") === null ? this.state.hairstyleIndex : localStorage.getItem("hairstyleIndex"),
            eyebrows: localStorage.getItem("eyebrows") === null ? this.state.eyebrows : localStorage.getItem("eyebrows"),
            facialHair: localStorage.getItem("facialHair") === null ? this.state.facialHair : localStorage.getItem("facialHair"),
            chestHair: localStorage.getItem("chestHair") === null ? this.state.chestHair : localStorage.getItem("chestHair"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("hairstyleIndex", this.state.hairstyleIndex);
        localStorage.setItem("eyebrows", this.state.eyebrows);
        localStorage.setItem("facialHair", this.state.facialHair);
        localStorage.setItem("chestHair", this.state.chestHair);
    }

    componentDidUpdate(){

        if(this.props.tipStyle === 'hairstyle'){
            let spanElements = document.getElementsByClassName('characters__styles-list')[0].children;

            for(let el of spanElements){
                el.classList.remove('active');
            }

            document.getElementsByClassName('characters__styles-list')[0].children[this.state.hairstyleIndex].classList.add('active');
        }
    }

    handlerChangeEyebrows(event){
        this.setState({
            eyebrows: event.target.value,
        })
    }

    handlerChangeFacialHair(event){
        this.setState({
            facialHair: event.target.value,
        })
    }

    handlerChangeChestHair(event){
        this.setState({
            chestHair: event.target.value,
        })
    }
    
    handlerClick(event){

        this.setState({
            hairstyleIndex: event.currentTarget.id,
        })

        let spanElements = document.getElementsByClassName('characters__styles-list')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }

    render() {
        switch(this.props.tipStyle){
            case 'brows':
                return(
                    <div className="characters__styles">
                        <div className="characters__styles-rows" key={1}>
                            <div className="characters__styles-title title">
                                <p>Брови</p>
                            </div>
                            <div className="characters__styles-value range">
                                <input type="range" min="0" max="33" defaultValue={this.state.eyebrows} step="1" onChange={this.handlerChangeEyebrows}/>
                                <p>Тип: {this.state.eyebrows}</p>
                            </div>
                        </div>
                    </div>
                )
            case 'beard':
                return(
                    <div className="characters__styles">
                        <div className="characters__styles-rows" key={2}>
                            <div className="characters__styles-title title">
                                <p>Волосы на лице</p>
                            </div>
                            <div className="characters__styles-value range">
                                <input type="range" min="0" max="28" defaultValue={this.state.facialHair} step="1" onChange={this.handlerChangeFacialHair}/>
                                <p>Тип: {this.state.facialHair}</p>
                            </div>
                        </div>
                    </div>
                )
            case 'hair':
                return(
                    <div className="characters__styles">
                        <div className="characters__styles-rows" key={3}>
                            <div className="characters__styles-title title">
                                <p>Волосы на груди</p>
                            </div>
                            <div className="characters__styles-value range">
                                <input type="range" min="0" max="16" defaultValue={this.state.chestHair} step="1" onChange={this.handlerChangeChestHair}/>
                                <p>Тип: {this.state.chestHair}</p>
                            </div>
                        </div>
                    </div>
                )
            default:
                if(this.props.sex){
                    return(
                        <div className="characters__styles">
                            <div className="characters__styles-title title">
                                <p>Прическа</p>
                            </div>
                            <div className="characters__styles-list">
                                {
                                    this.state.hairstyleM.map((name, index) => (
                                        <span key={index} id={index} onClick={this.handlerClick}>
                                            <img src={`/img/charactersEdit/hairstyleM/hairM__${index}.png`} alt={name} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                else{
                    return(
                        <div className="characters__styles">
                            <div className="characters__styles-title title">
                                <p>Прическа</p>
                            </div>
                            <div className="characters__styles-list">
                                {
                                    this.state.hairstyleF.map((name, index) => (
                                        <span key={index} id={index} onClick={this.handlerClick}>
                                            <img src={`/img/charactersEdit/hairstyleF/hairF__${index}.png`} alt={name} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
        }
    }
}
