import React, { Component } from 'react'

export default class CharacterClothes extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             
            listTopsIndex: 0,
            listTopsMValue: [0, 6, 13],
            listTopsFValue: [3, 9, 10],

            listLegsIndex: 0,
            listLegsMValue: [1, 6, 20],
            listLegsFValue: [6, 28, 43],

            listShoesIndex: 0,
            listShoesMValue: [1, 5, 7],
            listShoesFValue: [14, 16, 27],
        }

        this.handlerClickTops = this.handlerClickTops.bind(this);
        this.handlerClickLegs = this.handlerClickLegs.bind(this);
        this.handlerClickShoes = this.handlerClickShoes.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            listTopsIndex: localStorage.getItem("listTopsIndex") === null ? this.state.listTopsIndex : localStorage.getItem("listTopsIndex"),
            listLegsIndex: localStorage.getItem("listLegsIndex") === null ? this.state.listLegsIndex : localStorage.getItem("listLegsIndex"),
            listShoesIndex: localStorage.getItem("listShoesIndex") === null ? this.state.listShoesIndex : localStorage.getItem("listShoesIndex"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("listTopsIndex", this.state.listTopsIndex);
        localStorage.setItem("listLegsIndex", this.state.listLegsIndex);
        localStorage.setItem("listShoesIndex", this.state.listShoesIndex);
    }

    componentDidUpdate(){

        let spanElements = document.getElementsByClassName('characters__clothes-list')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        switch(this.props.tipClothe){
            case "tops":
                document.getElementsByClassName('characters__clothes-list')[0].children[this.state.listTopsIndex].classList.add('active');
                break;
            case "legs":
                document.getElementsByClassName('characters__clothes-list')[0].children[this.state.listLegsIndex].classList.add('active');
                break;
            case "shoes":
                document.getElementsByClassName('characters__clothes-list')[0].children[this.state.listShoesIndex].classList.add('active');
                break;
            default:
                break;
        }
    }

    handlerClickTops(event){
        this.setState({
            ...this.state,
            listTopsIndex: event.currentTarget.id,
        })

    }

    handlerClickLegs(event){
        this.setState({
            ...this.state,
            listLegsIndex: event.currentTarget.id,
        })
    }

    handlerClickShoes(event){
        this.setState({
            ...this.state,
            listShoesIndex: event.currentTarget.id,
        })
    }

    render() {
        switch(this.props.tipClothe){
            case "legs":
                if(this.props.sex){
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Нижняя одежда</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listLegsMValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickLegs}>
                                            <img src={`/img/charactersEdit/LegsM/LegsM_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Нижняя одежда</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listLegsFValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickLegs}>
                                            <img src={`/img/charactersEdit/LegsF/LegsF_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            case "shoes":
                if(this.props.sex){
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Обувь</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listShoesMValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickShoes}>
                                            <img src={`/img/charactersEdit/ShoesM/ShoesM_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Обувь</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listShoesFValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickShoes}>
                                            <img src={`/img/charactersEdit/ShoesF/ShoesF_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
            default:
                if(this.props.sex){
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Верхняя одежда</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listTopsMValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickTops}>
                                            <img src={`/img/charactersEdit/TopsM/TopsM_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
                else{
                    return (
                        <div className="characters__clothes">
                            <div className="characters__clothes-title title">
                                <p>Верхняя одежда</p>
                            </div>
                            <div className="characters__clothes-list">
                                {
                                    this.state.listTopsFValue.map((value, index) => (
                                        <span key={index} id={index} onClick={this.handlerClickTops}>
                                            <img src={`/img/charactersEdit/TopsF/TopsF_${value}.png`} alt={`Одежла: ${index}`} />
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                    )
                }
        }
    }
}


// !! Trebue de facut da datele sa nu dispara cind faci faci move 