import React, { Component } from 'react'

export default class CharacterParant extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            fatherKey: 0,
            motherKey: 0,

            fathers: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 42, 43, 44],
            fatherNames: ["Benjamin", "Daniel", "Joshua", "Noah", "Andrew", "Juan", "Alex", "Isaac", "Evan", "Ethan", "Vincent", "Angel", "Diego", "Adrian", "Gabriel", "Michael", "Santiago", "Kevin", "Louis", "Samuel", "Anthony", "Claude", "Niko", "John"],
            
            mothers: [21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 45],
            motherNames: ["Hannah", "Aubrey", "Jasmine", "Gisele", "Amelia", "Isabella", "Zoe", "Ava", "Camila", "Violet", "Sophia", "Evelyn", "Nicole", "Ashley", "Gracie", "Brianna", "Natalie", "Olivia", "Elizabeth", "Charlotte", "Emma", "Misty"],
        }

        this.handlerClick = this.handlerClick.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            fatherKey: localStorage.getItem("fatherKey") === null ? this.state.fatherKey : localStorage.getItem("fatherKey"),
            motherKey: localStorage.getItem("motherKey") === null ? this.state.motherKey : localStorage.getItem("motherKey"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("fatherKey", this.state.fatherKey);
        localStorage.setItem("motherKey", this.state.motherKey);
    }

    componentDidUpdate(){
        let spanElements = document.getElementsByClassName('characters__parants-list')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        if(this.props.parentSex){
            document.getElementsByClassName('characters__parants-list')[0].children[this.state.fatherKey].classList.add('active');
        }
        else{
            document.getElementsByClassName('characters__parants-list')[0].children[this.state.motherKey].classList.add('active')
        }
    }
    
    handlerClick(event){

        if(this.props.parentSex){
            this.setState({
                ...this.state,
                fatherKey: event.currentTarget.id,
            })
        }
        else{
            this.setState({
                ...this.state,
                motherKey: event.currentTarget.id,
            })
        }

        let spanElements = document.getElementsByClassName('characters__parants-list')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }

    render() {
        if(this.props.parentSex){
            return (
                <div className="characters__parants">
                    <div className="characters__parants-title title">
                        <p>Папа</p>
                    </div>
                    <div className="characters__parants-list">
                        {
                            this.state.fatherNames.map((name, index) => (
                                <span key={index} id={index} onClick={this.handlerClick}>
                                    <img src={`/img/charactersEdit/father/father_${index}.png`} alt={name} />
                                </span>
                            ))
                        }
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="characters__parants">
                    <div className="characters__parants-title title">
                        <p>Мама</p>
                    </div>
                    <div className="characters__parants-list">
                        {
                            this.state.motherNames.map((name, index) => (
                                <span key={index} id={index} onClick={this.handlerClick}>
                                    <img src={`/img/charactersEdit/mother/mother_${index}.png`} alt={name} />
                                </span>
                            ))
                        }
                    </div>
                </div>
            )
        }
    }
}
