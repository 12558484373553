import React, { Component } from 'react'

export default class AdditionallyParent extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            similarity: 0.5,
            leather: 0.5,
        }

        this.handlersimilarityValue = this.handlersimilarityValue.bind(this);
        this.handlerLeatherValue = this.handlerLeatherValue.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            similarity: localStorage.getItem("similarity") === null ? this.state.similarity : localStorage.getItem("similarity"),
            leather: localStorage.getItem("leather") === null ? this.state.leather : localStorage.getItem("leather"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("similarity", this.state.similarity);
        localStorage.setItem("leather", this.state.leather);
    }

    handlersimilarityValue(event){
        this.setState({
            ...this.state,
            similarity: event.target.value
        })

    }
    handlerLeatherValue(event){
        this.setState({
            ...this.state,
            leather: event.target.value
        })
    }
    
    render() {
        return (
            <div className="additionally__parent">
                <div className="additionally__rows">
                    <div className="additionally__parent-title title">
                        <p>Схожесть</p>
                    </div>
                    <div className="additionally__parent-similarity range">
                        <input type="range" min="0" max="1" value={this.state.similarity} step="0.1" onChange={this.handlersimilarityValue} />
                    </div>
                    <div className="additionally__parent-value value">
                        <p>{this.state.similarity * 100}%</p>
                    </div>
                </div>
                <div className="additionally__rows">
                    <div className="additionally__parent-title title">
                        <p>Кожа</p>
                    </div>
                    <div className="additionally__parent-leather range">
                        <input type="range" min="0" max="1" value={this.state.leather} step="0.1" onChange={this.handlerLeatherValue} />
                    </div>
                    <div className="additionally__parent-value value">
                        <p>{this.state.leather * 100}%</p>
                    </div>
                </div>
            </div>
        )
    }
}
