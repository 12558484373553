import React, { Component } from 'react'

export default class EditInfo extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            infoSex: true,
            infoName: "",
            infoSurname: "",
            infoAge: "",
        }

        this.handlerClick = this.handlerClick.bind(this);

        this.handlerChangeName = this.handlerChangeName.bind(this);
        this.handlerChangeSurname = this.handlerChangeSurname.bind(this);
        this.handlerChangeAge = this.handlerChangeAge.bind(this);
    }

    componentDidMount(){

        this.setState({
            infoSex: localStorage.getItem("infoSex") === null ? this.state.infoSex : (localStorage.getItem("infoSex") === "true"),
            infoName: localStorage.getItem("infoName") === null ? this.state.infoName : localStorage.getItem("infoName"),
            infoSurname: localStorage.getItem("infoSurname") === null ? this.state.infoSurname : localStorage.getItem("infoSurname"),
            infoAge: localStorage.getItem("infoAge") === null ? this.state.infoAge : localStorage.getItem("infoAge"),
        })
        
    }

    componentWillUnmount() {
        localStorage.setItem("infoSex", this.state.infoSex);
        localStorage.setItem("infoName", this.state.infoName);
        localStorage.setItem("infoSurname", this.state.infoSurname);
        localStorage.setItem("infoAge", this.state.infoAge);
    }

    componentDidUpdate(){
        if(this.state.infoSex){
            document.getElementsByClassName('characters__gender-type')[0].children[0].classList.add('active');
        }
        else{
            document.getElementsByClassName('characters__gender-type')[0].children[1].classList.add('active');
        }
    }

    handlerClick(event){

        if(!event.currentTarget.classList.contains('active')){
            this.setState({
                infoSex: !this.state.infoSex,
            })
        }

        let spanElements = document.getElementsByClassName('characters__gender-type')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }

    handlerChangeName(event){
        this.setState({
            infoName: event.target.value,
        })
    }

    handlerChangeSurname(event){
        this.setState({
            infoSurname: event.target.value,
        })
    }

    handlerChangeAge(event){
        if(event.target.value < 18){
            event.target.value = 18
        }
        else if(event.target.value > 100){
            event.target.value = 100
        }

        this.setState({
            infoAge: event.target.value,
        })

    }
    
    render() {
        return (
            <div className="characters__rows">
                <div className="characters__gender">
                    <div className="characters__gender-title title">
                        <span>Выберите пол</span>
                    </div>
                    <div className="characters__gender-type">
                        <span onClick={this.handlerClick}>
                            <img src="/img/charactersEdit/icons/selectGender/man.png" alt="" />
                        </span>
                        <span onClick={this.handlerClick}>
                            <img src="/img/charactersEdit/icons/selectGender/woman.png" alt="" />
                        </span>
                    </div>
                </div>
                <div className="characters__data">
                    <div className="characters__data-title title">
                        <span>Персональные данные</span>
                    </div>
                    <div className="characters__data-input">
                        <input type="text" id="name" placeholder="Введите Имя" defaultValue={this.state.infoName} onChange={this.handlerChangeName} />
                        <input type="text" id="surname" placeholder="Введите Фамилию" defaultValue={this.state.infoSurname} onChange={this.handlerChangeSurname} />
                        <input type="number" id="age" min="10" max="100" placeholder="Укажите возраст" defaultValue={this.state.infoAge} onBlur={this.handlerChangeAge} />
                    </div>
                </div>
                <div className="characters__cursor-info">
                    <span>Нажмите на ~ (ё) чтоб курсор появился.</span>
                </div>
            </div>
        )
    }
}
