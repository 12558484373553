import React, { Component } from 'react'

export default class AdditionallyStyle extends Component {
    constructor(props) {
        super(props)
    
        this.state = {

            basicColorsIndex: [0, 0, 0, 0],
            additionalColorsIndex: 0,

            colors: [
                    "211f1c", "55362f", "4b382e", "4d291b", 
                    "70351e", "904422", "a55c36", "a56944", 
                    "ac744f", "ae7d57", "be9161", "cda670", 
                    "c8a370", "d5a861", "e0b775", "e8c487", 
                    "b78457", "a85d3d", "963523", "7c1411", 
                    "921812", "a81c14", "cb371e", "de411b", 
                    "be532f", "d34d21", "907867", "a78e7a", 
                    "d4bda9", "e4cfbe", "775262", "8f5973", 
                    "ad4a6b", "f845cc", "fc5794", "f9a1b2", 
                    "09a497", "08828e", "084e7c", "63a15a", 
                    "379665", "25705d", "bec22f", "9ab516", 
                    "61a526", "e8bd56", "f2c20e", "f2980e", 
                    "fc8b14", "f76415", "fe771c", "f1501f", 
                    "ef3c17", "c81414", "9b0a0e", "291a14", 
                    "3f241c", "502c1e", "47271d", "4d2c1f", 
                    "39251c", "080a0e", "ad8d67", "c59762"],
        }

        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);

        this.handlerClick = this.handlerClick.bind(this);
        this.handlerClickAdditional = this.handlerClickAdditional.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            basicColorsIndex: localStorage.getItem("basicColorsIndex") === null ? this.state.basicColorsIndex : localStorage.getItem("basicColorsIndex").split(','),
            additionalColorsIndex: localStorage.getItem("additionalColorsIndex") === null ? this.state.additionalColorsIndex : localStorage.getItem("additionalColorsIndex"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("basicColorsIndex", this.state.basicColorsIndex);
        localStorage.setItem("additionalColorsIndex", this.state.additionalColorsIndex);
    }

    componentDidUpdate(){

        let Elements = document.getElementsByClassName('additionally__style-colors')[0].children;

        for(let el of Elements){
            el.classList.remove('active');
        }

        if(this.props.tipStyle === 'hairstyle'){
            let Elements = document.getElementsByClassName('additionally__style-colors')[1].children;

            for(let el of Elements){
                el.classList.remove('active');
            }
        }

        switch(this.props.tipStyle){
            case 'hairstyle':
                document.getElementsByClassName('additionally__style-colors')[0].children[this.state.basicColorsIndex[0]].classList.add('active');
                document.getElementsByClassName('additionally__style-colors')[1].children[this.state.additionalColorsIndex].classList.add('active');
                break;
            case 'brows':
                document.getElementsByClassName('additionally__style-colors')[0].children[this.state.basicColorsIndex[1]].classList.add('active');
                break;
            case 'beard':
                document.getElementsByClassName('additionally__style-colors')[0].children[this.state.basicColorsIndex[2]].classList.add('active');
                break;
            case 'hair':
                document.getElementsByClassName('additionally__style-colors')[0].children[this.state.basicColorsIndex[3]].classList.add('active');
                break;
            default:
                break;
        }
    }
    
    handlerClick(event){
        let index;

        switch(this.props.tipStyle){
            case 'hairstyle':
                index = 0;
                break;
            case 'brows':
                index = 1;
                break;
            case 'beard':
                index = 2;
                break;
            case 'hair':
                index = 3;
                break;
            default:
                break;
        }

        this.setState({
            basicColorsIndex: [...this.state.basicColorsIndex.slice(0, index).concat([event.currentTarget.id]), ...this.state.basicColorsIndex.slice(index + 1)],
        })
    }

    handlerClickAdditional(event){
        this.setState({
            additionalColorsIndex: event.currentTarget.id,
        })
    }
    
    render() {
        if(this.props.tipStyle === 'hairstyle'){
            return (
                <div className="additionally__style">
                    <div className="additionally__rows">
                        <div className="additionally__style-title title">
                            <p>Основной</p>
                        </div>
                        <div className="additionally__style-colors">
                            {
                                this.state.colors.map((color, index) => (
                                    <div key={index} id={index} className="additionally__style-color" style={{backgroundColor: `#${color}`}} onClick={this.handlerClick} />
                                ))
                            }
                        </div>
                    </div>
    
                    <div className="additionally__rows">
                        <div className="additionally__style-title title">
                            <p>Дополн</p>
                        </div>
                        <div className="additionally__style-colors">
                            {
                                this.state.colors.map((color, index) => (
                                    <div key={index} id={index} className="additionally__style-color" style={{backgroundColor: `#${color}`}} onClick={this.handlerClickAdditional} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        }
        else{
            return (
                <div className="additionally__style">
                    <div className="additionally__rows">
                        <div className="additionally__style-title title">
                            <p>Основной</p>
                        </div>
                        <div className="additionally__style-colors">
                            {
                                this.state.colors.map((color, index) => (
                                    <div key={index} id={index} className="additionally__style-color" style={{backgroundColor: `#${color}`}} onClick={this.handlerClick} />
                                ))
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }
}
