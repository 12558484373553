import React, { Component } from 'react'

export default class CharacterGene extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            extraIndex: [{'id': 'nose'  ,'value': 0 },
                         {'id': 'brows' ,'value': 6 }, 
                         {'id': 'eye'   ,'value': 8 }, 
                         {'id': 'lips'  ,'value': 9 }, 
                         {'id': 'neck'  ,'value': 10}],

            faceFeatures: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],

            noseName: ["Ширина носа", "Высота носа", "Длина кончика носа", "Глубина носа", "Высота кончика носа", "Горбинка"],
            browsName: ["Высота бровей", "Глубина бровей"],
            eyeName: ["Размер глаз"],
            lipsName: ["Толщина губ"],
            neckName: ["Высота скул", "Ширина скул", "Глубина щеки" , "Ширина челюсти", "Форма челюсти", "Высота подбородка", "Глубина подбородка", "Ширина подбородка", "Подбородочный отступ", "Обхват шеи"],
        
            lipstick: 0,
            EyeColor: 0,
        }

        this.handlerChangeValue = this.handlerChangeValue.bind(this);
        this.handlerChangelips = this.handlerChangelips.bind(this);
        this.handlerChangeEyeColor = this.handlerChangeEyeColor.bind(this);
    }

    componentDidMount(){
        this.setState({
            ...this.state,
            faceFeatures: localStorage.getItem("faceFeatures") === null ? this.state.faceFeatures : localStorage.getItem("faceFeatures").split(','),
            lipstick: localStorage.getItem("lipstick") === null ? this.state.lipstick : localStorage.getItem("lipstick"),
            EyeColor: localStorage.getItem("EyeColor") === null ? this.state.EyeColor : localStorage.getItem("EyeColor"),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("faceFeatures", this.state.faceFeatures);
        localStorage.setItem("lipstick", this.state.lipstick);
        localStorage.setItem("EyeColor", this.state.EyeColor);
    }

    handlerChangeValue(index, event){

        index += this.state.extraIndex.find(x => x.id === this.props.tipGene).value

        this.setState({
            ...this.state,
            faceFeatures: [...this.state.faceFeatures.slice(0, index).concat([event.target.value]), ...this.state.faceFeatures.slice(index + 1)],
        })
    }

    handlerChangelips(event){
        this.setState({
            ...this.state,
            lipstick: event.target.value,
        })
    }

    handlerChangeEyeColor(event){
        this.setState({
            ...this.state,
            EyeColor: event.target.value,
        })
    }
    
    render() {
        let extraIndex = this.state.extraIndex.find(x => x.id === this.props.tipGene).value;

        switch(this.props.tipGene){
            case "brows": 
                return (
                    <div className="characters__genes">
                        <div className="characters__genes-list">
                            {
                                this.state.browsName.map((name,index) => (

                                    <div className="characters__genes-rows" key={index + extraIndex}>
                                        <div className="characters__genes-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__genes-value range">
                                            <input type="range" min="-1" max="1" defaultValue={this.state.faceFeatures[index + extraIndex]} step="0.1" onChange={this.handlerChangeValue.bind(this, index)} />
                                            <p>{this.state.faceFeatures[index + extraIndex]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            case "eye": 
                return (
                    <div className="characters__genes">
                        <div className="characters__genes-list">
                            {
                                this.state.eyeName.map((name,index) => (

                                    <div className="characters__genes-rows" key={index + extraIndex}>
                                        <div className="characters__genes-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__genes-value range">
                                            <input type="range" min="-1" max="1" defaultValue={this.state.faceFeatures[index + extraIndex]} step="0.1" id={index} onChange={this.handlerChangeValue.bind(this, index)} />
                                            <p>{this.state.faceFeatures[index + extraIndex]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="characters__genes-rows" key={extraIndex}>
                                <div className="characters__genes-title title">
                                    <p>Цвет глаз</p>
                                </div>
                                <div className="characters__genes-value range">
                                    <input type="range" min="0" max="31" defaultValue={this.state.EyeColor} step="1" onChange={this.handlerChangeEyeColor}/>
                                    <p>Тип: {this.state.EyeColor}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case "lips": 
                return (
                    <div className="characters__genes">
                        <div className="characters__genes-list">
                            {
                                this.state.lipsName.map((name,index) => (

                                    <div className="characters__genes-rows" key={index + extraIndex}>
                                        <div className="characters__genes-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__genes-value range">
                                            <input type="range" min="-1" max="1" defaultValue={this.state.faceFeatures[index + extraIndex]} step="0.1" id={index} onChange={this.handlerChangeValue.bind(this, index)} />
                                            <p>{this.state.faceFeatures[index + extraIndex]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="characters__genes-rows" key={extraIndex}>
                                <div className="characters__genes-title title">
                                    <p>Губная помада</p>
                                </div>
                                <div className="characters__genes-value range">
                                    <input type="range" min="0" max="9" defaultValue={this.state.lipstick} step="1" onChange={this.handlerChangelips}/>
                                    <p>Тип: {this.state.lipstick}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            case "neck": 
                return (
                    <div className="characters__genes">
                        <div className="characters__genes-list">
                            {
                                this.state.neckName.map((name,index) => (

                                    <div className="characters__genes-rows" key={index + extraIndex}>
                                        <div className="characters__genes-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__genes-value range">
                                            <input type="range" min="-1" max="1" defaultValue={this.state.faceFeatures[index + extraIndex]} step="0.1" id={index} onChange={this.handlerChangeValue.bind(this, index)} />
                                            <p>{this.state.faceFeatures[index + extraIndex]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            default:
                return (
                    <div className="characters__genes">
                        <div className="characters__genes-list">
                            {
                                this.state.noseName.map((name,index) => (

                                    <div className="characters__genes-rows" key={index + extraIndex}>
                                        <div className="characters__genes-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__genes-value range">
                                            <input type="range" min="-1" max="1" defaultValue={this.state.faceFeatures[index + extraIndex]} step="0.1" id={index} onChange={this.handlerChangeValue.bind(this, index)} />
                                            <p>{this.state.faceFeatures[index + extraIndex]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
        }
    } 
}
