import React, { Component } from 'react'

import CharacterGene from './CharacterGene';

export default class EditGene extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             tipGene: 'nose',
        }

        this.handlerClick = this.handlerClick.bind(this);
    }
    handlerClick(event){
        if(event.currentTarget.classList.contains('active')) return

        this.setState ({
            tipGene: event.currentTarget.id,
        })

        let spanElements = document.getElementsByClassName('characters__leftBar')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }
    
    render() {
        return (
            <div className="characters__rows">
                <div className="characters__gene">
                    <div className="characters__leftBar">
                        <span className="active" onClick={this.handlerClick} id="nose">
                            <img src="/img/charactersEdit/icons/genes/nose.png" alt="nose" />
                        </span>
                        <span onClick={this.handlerClick} id="brows">
                            <img src="/img/charactersEdit/icons/genes/brows.png" alt="brows" />
                        </span>
                        <span onClick={this.handlerClick} id="eye">
                            <img src="/img/charactersEdit/icons/genes/eye.png" alt="eye" />
                        </span>
                        <span onClick={this.handlerClick} id="lips">
                            <img src="/img/charactersEdit/icons/genes/lips.png" alt="lips" />
                        </span>
                        <span onClick={this.handlerClick} id="neck">
                            <img src="/img/charactersEdit/icons/genes/neck.png" alt="neck" />
                        </span>
                    </div>
                    <CharacterGene tipGene={this.state.tipGene} />
                </div>
            </div>
        )
    }
}
