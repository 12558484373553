import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Login from './authorization/Login.js';
import Register from './authorization/Register.js';
import Characters from "./characters/Characters.js";
import CharactersEdit from "./charactersEdit/CharactersEdit.js";

function App() {
  return (
    <Router>
        <Switch>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/Register">
            <Register />
          </Route>
          <Route path="/Characters">
            <Characters />
          </Route>
          <Route path="/CharactersEdit">
            <CharactersEdit />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;