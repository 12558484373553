import React, { Component } from 'react'

export default class EditDisadvantage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {

            listDisadvantageValue: [0, 0, 0, 0, 0, 0],
            listDisadvantageNumber: [0, 3, 5, 7, 9, 11],
            listDisadvantageMaxValue: [23, 33, 32, 10, 17, 11],
            listDisadvantageName: ['Пятна','Старение','Румянец','Урон от солнца','Родинки/веснушки','Пятна на теле'],
        }

        this.handlerChange = this.handlerChange.bind(this);
    }
    
    componentDidMount(){
        this.setState({
            ...this.state,
            listDisadvantageValue: localStorage.getItem("listDisadvantageValue") === null ? this.state.listDisadvantageValue : localStorage.getItem("listDisadvantageValue").split(','),
        })
    }

    componentWillUnmount() {
        localStorage.setItem("listDisadvantageValue", this.state.listDisadvantageValue);
    }

    handlerChange(index, event){
        this.setState({
            listDisadvantageValue: [...this.state.listDisadvantageValue.slice(0, index).concat([event.target.value]), ...this.state.listDisadvantageValue.slice(index + 1)],
        })
    }

    render() {
        return (
            <div className="characters__rows">
                <div className="characters__disadvantage">
                    <div className="characters__disadvantages">
                        <div className="characters__disadvantages-list">
                            {
                                this.state.listDisadvantageName.map((name, index) => (
                                    <div className="characters__disadvantage-rows" key={index}>
                                        <div className="characters__disadvantage-title title">
                                            <p>{name}</p>
                                        </div>
                                        <div className="characters__disadvantage-value range">
                                            <input type="range" min="0" max={this.state.listDisadvantageMaxValue[index]} value={this.state.listDisadvantageValue[index]} step="1" onChange={this.handlerChange.bind(this, index)}/>
                                            <p>Тип: {this.state.listDisadvantageValue[index]}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
