import React, { Component } from 'react'

import CharacterParant from './CharacterParant';
import AdditionallyParent from '../additionally/AdditionallyParent';

export default class EditParent extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            parentSex: true,
        }

        this.handlerClick = this.handlerClick.bind(this);
    }
    
    handlerClick(event){
        if(!event.currentTarget.classList.contains('active')){
            this.setState ({
                parentSex: !this.state.parentSex,
            })
        }

        let spanElements = document.getElementsByClassName('characters__leftBar')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }

    render() {
        return (
            <div className="characters__rows">
                <div className="characters__parent">
                    <div className="characters__leftBar">
                        <span className="active" onClick={this.handlerClick}>
                            <img src="/img/charactersEdit/icons/selectGender/man.png" alt="man" />
                        </span>
                        <span onClick={this.handlerClick}>
                            <img src="/img/charactersEdit/icons/selectGender/woman.png" alt="woman" />
                        </span>
                    </div>
                    <CharacterParant parentSex={this.state.parentSex}/>
                    <AdditionallyParent />
                </div>
            </div>
        )
    }
}
