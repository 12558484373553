import React, { Component } from 'react'

import CharacterClothes from './CharacterClothes';

export default class EditClothes extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             tipClothe: 'tops',
        }

        this.handlerClick = this.handlerClick.bind(this);
    }
    
    handlerClick(event){
        if(event.currentTarget.classList.contains('active')) return

        this.setState ({
            tipClothe: event.currentTarget.id,
        })

        let spanElements = document.getElementsByClassName('characters__leftBar')[0].children;

        for(let el of spanElements){
            el.classList.remove('active');
        }

        event.currentTarget.classList.add('active');
    }

    render() {
        return (
            <div className="characters__rows">
                <div className="characters__clothe">
                    <div className="characters__leftBar">
                        <span className="active" onClick={this.handlerClick} id="tops">
                            <img src="/img/charactersEdit/icons/clothes/tops.png" alt="tops" />
                        </span>
                        <span onClick={this.handlerClick} id="legs">
                            <img src="/img/charactersEdit/icons/clothes/legs.png" alt="legs" />
                        </span>
                        <span onClick={this.handlerClick} id="shoes">
                            <img src="/img/charactersEdit/icons/clothes/shoes.png" alt="shoes" />
                        </span>
                    </div>
                    <CharacterClothes tipClothe={this.state.tipClothe} sex={false}/>
                </div>
            </div>
        )
    }
}
